.ag-theme-alpine .ag-header {
  background-color: #F4F8FB;
  border-bottom-color: #EDF1F4;
}

.ag-theme-alpine .ag-root-wrapper {
  border: 0px;
}

.ag-theme-alpine .ag-row {
  height: 45px;
  border: 1px;
  border-color: #FFFFFF;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #F4F8FB;
}

.ag-theme-alpine .ag-row-odd:hover,
.ag-theme-alpine .ag-row-even:hover {
  background-color: #E7F3FC;
}

.ag-theme-alpine .ag-row-selected {
  background-color: #E7F3FC;
}

.ag-theme-alpine .ag-menu,
.ag-theme-alpine .ag-menu-header {
  background-color: #ffffff;
}

.ag-theme-alpine .ag-cell {
  line-height: 45px;
}

.ag-theme-alpine .ag-checkbox-input {
  position: absolute;
}

.ag-theme-alpine .ag-cell.ag-cell-last-left-pinned,
.ag-theme-alpine .ag-pinned-left-header {
  border-right-color: #dbdbdf !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

/* Search Dropdown */

.ag-theme-alpine .ag-cell-editor-autocomplete-input {
  border: 0px !important;
  min-height: 40px !important;
  background-color: #ffffff !important;
  padding-left: 10px !important;
}

.DropStyle {
  background-color: #ffffff;
  line-height: 40px;
  border: 1px solid #BBBFC6;
  margin-top: 4px;
  font-size: 14px;
  color: #333333;
  border-radius: 3px;
}

.DropStyle .empty {
  margin-left: 10px;
  margin-right: 10px;
}

.DropStyle .selected {
  background-color: #E7F3FC;
}

.DropStyle div:hover {
  background-color: #E7F3FC;
  cursor: pointer;
}

.DropStyle span {
  padding-left: 10px;
  padding-right: 10px;
}

.DropStyle span strong {
  font-weight: normal !important;
}
