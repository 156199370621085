.errorComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.errorBox {
    background-color: #fef1f1;
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
}

.errorMessage {
    font-size: 14px;
    margin-bottom: 15px;
}

.controls {
    display: flex;
    justify-content: space-evenly;
    margin: 0 25%;
}

.tryAgain {
    background-color: #74bc00;
    border-radius: 10px;
    padding: 8px;
    color: white;
}

.close {
    background-color: white;
    border-radius: 10px;
    padding: 8px;
    color: grey;
    border: 1px solid grey;
}